import { Box, BackgroundImage, Container, Group, Text, Flex, Burger, Drawer, Center } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import './Header.css'
import { IconBrandVk, IconBrandWhatsapp, IconBrandTelegram } from "@tabler/icons-react";

const data = [
    {title: 'Обо мне', href: '#about'},
    {title: 'Прайс', href: '#price'},
    {title: 'Портфолио', href: '#portfolio'},
    {title: 'Контакты', href: '#contact'},
]
const logo = require(`../images/logo.png`)

export const HeaderMenu: React.FC = (type: any) => {
    const [opened, { toggle }] = useDisclosure(false);

    const links = data.map((item, index) => {
      return (
          <Box component="a" href={item.href} key={index} className="hoverUnderline">
            <Text component="p" key={index} className="header-links">{item.title}</Text>
          </Box>
      )
    })

    const linksMobile = data.map((item, index) => {
      return (
            <Text onClick={ toggle } component="a" href={item.href} key={index} className="header-links-m">{item.title}</Text>
      )
    })

    return (
      <Box className="header-main">
        <Container maw={1180}>
         <Group position="apart">
            <BackgroundImage src={logo} className="header-logo"/>
            <Group position="apart">
                {links}
                <Text className="telephone" component="a" href="tel:89383456532">
                  +7(938)345-65-32
                </Text>
                <Burger color="#FFF" size={32} opened={opened} onClick={ toggle } sx={{zIndex: 100000}} className="burger"/>
            </Group>
         </Group>
        </Container>
        <Drawer.Root
            size={'100%'}
            opened={opened}
            onClose={toggle}
            zIndex={10000}
            ff={'Inter'}>
            <Drawer.Content>
              <Drawer.Header>
              <BackgroundImage src={logo} w={'3rem'} h={'3rem'}/>
                <Burger color="#FFF" mr={'1rem'} size={24} opened={opened} onClick={ toggle } sx={{zIndex: 100000}} />
              </Drawer.Header>
              <Drawer.Body>
                {linksMobile}
                <Text className="telephone-mobile" component="a" href="tel:89383456532">
                  +7(938)345-65-32
                </Text>
                <Group mt={'4rem'}>
                  <Box component="a" href="https://t.me/toxic_arrr" bg={'#FFF'} p={'0.5rem'} sx={{borderRadius: '100%'}}>
                    <Center>
                        <IconBrandTelegram color="#000" size={16}/>
                    </Center>
                  </Box>
                  <Box component="a" href="https://vk.com/toxic_arrr" bg={'#FFF'} p={'0.5rem'} sx={{borderRadius: '100%'}}>
                    <Center>
                        <IconBrandVk color="#000" size={16}/>
                    </Center>
                  </Box>
                  <Box component="a" href="https://wa.me/+79383456532" bg={'#FFF'} p={'0.5rem'} sx={{borderRadius: '100%'}}>
                    <Center>
                        <IconBrandWhatsapp color="#000" size={16}/>
                    </Center>
                  </Box>
                </Group>
              </Drawer.Body>
            </Drawer.Content>
        </Drawer.Root>
      </Box>
    )
}