import { BackgroundImage, Box, Button, Container, Group, Flex, Text, TextInput } from "@mantine/core"
import './SectionFour.css'
import { IconCode, IconDeviceMobileCode, IconBrandCodesandbox, IconTimeDuration10 } from "@tabler/icons-react"
import { useState } from "react"
import { Carousel } from "@mantine/carousel"

type Props = {}

const descriptionData = [
  'Нет, я не пользуюсь конструкторами! Создание проекта происходит с использованием актуального и широкоиспользуемого стека технологий на базе языка программирования JavaScript',
  'Написание проекта происходит как для десктопной, так и для мобильной версии',
  'Создание макета сайта/приложения под ваш вкус, написание кода по ранее нарисованному макету, настройка формы обратной связи(если будет на сайте)',
  'В зависимости от объема проекта (обсуждается индивидуально). Время создания простого сайта-блога/сайта-визитки от 10 дней.'
]

export const SectionFour: React.FC = (props: Props) => {
  const [clicked, setClicked] = useState('')

  const data = [
    {
      title: 'Индивидуальный подход',
      icon: <IconCode color="#FFF" size={'2rem'} style={{margin: `1rem 0 8px 1rem`}}/>,
      color: '#2ECC71',
      handle: function() { setClicked('0')}
    },
    {
      title: 'Адаптивная верстка',
      icon: <IconDeviceMobileCode color="#FFF" size={'2rem'} style={{margin: `1rem 0 8px 1rem`}}/>,
      color: '#3498DB',
      handle: function() { setClicked('1')}
    },
    {
      title: 'Качественная сборка',
      icon: <IconBrandCodesandbox color="#FFF" size={'2rem'} style={{margin: `1rem 0 8px 1rem`}}/>,
      color: '#9B59B6',
      handle: function() { setClicked('2')}
    },
    {
      title: 'Сроки создания проекта',
      icon: <IconTimeDuration10 color="#FFF" size={'2rem'} style={{margin: `1rem 0 8px 1rem`}}/>,
      color: '#2C3E50',
      handle: function() { setClicked('3')}
    },
  ]

  interface ComponentProps {
    title: string,
    icon: any,
    color: string,
    handle: any
  }

  const BlockContainer: React.FC<ComponentProps> = ({title, icon, color, handle}) => {
    return (
      <Box className="sec-4-block" bg={color} onClick={handle}>
        {icon}
        <Text className="sec-4-block-title" mx={'auto'}>
          {title}
        </Text>
        <Box w={'100%'} mb={'1rem'} style={{position: 'absolute', bottom: 0}}>
          <Text className="sec-4-block-description" mx={'auto'}>
            Нажмите на блок...
          </Text>
        </Box>
      </Box>
    )
  }

  return (
    <Box className="sec-4-main">
      <Container maw={1180}>
          <Text className="sec-4-title">
          ПОЧЕМУ НЕОБХОДИМО ВЫБРАТЬ <span>МЕНЯ</span> КАК <span>ИСПОЛНИТЕЛЯ?</span>
          </Text>
          <Group position="center" spacing={6} className="sec-4-group">
            {data.map((item, index) => (
              <BlockContainer key={index} title={item.title} icon={item.icon} color={item.color} handle={item.handle}/>
            ))}
          </Group>
          <Carousel className="sec-4-carousel" slidesToScroll={1} initialSlide={0}>
            {data.map((item, index) => (
              <BlockContainer key={index} title={item.title} icon={item.icon} color={item.color} handle={item.handle}/>
            ))}
          </Carousel>
          <Text className="sec-4-description" mx={'auto'}>
            {clicked === '0' && descriptionData[0]}
            {clicked === '1' && descriptionData[1]}
            {clicked === '2' && descriptionData[2]}
            {clicked === '3' && descriptionData[3]}
          </Text>
      </Container>
    </Box>
  )
}
