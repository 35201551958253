import { BackgroundImage, Box, Button, Container, Group, Flex, Text, TextInput, Divider } from "@mantine/core"
import { Carousel } from "@mantine/carousel"
import './SectionFive.css'
import './Contact.css'
import { IconCode, IconDeviceMobileCode, IconLayoutGrid, IconBrandCodesandbox, IconArrowBarToLeft, IconArrowBarToRight, IconX } from "@tabler/icons-react"
import { useState } from "react"
import { ContactPrice } from "./ContactPrice"
type Props = {}

const data = [
    {
        icon: <Box pt={'1rem'} mx={'auto'} w={36}><IconCode color="#FFF" size={36}/></Box>,
        title: 'Standart',
        description: [
            {label: 'Создание макета сайта'},
            {label: 'Верстка сайта по макету'},
        ],
        price: 'от 15000 ₽',
        color: '#900020'
    },
    {
        icon: <Box pt={'1rem'} mx={'auto'} w={36}><IconCode color="#FFF" size={36}/></Box>,
        title: 'Standart+',
        description: [
            {label: 'Создание макета сайта'},
            {label: 'Верстка сайта по макету'},
            {label: 'Настройка формы обратной связи'},
            {label: 'Место под хостинг в подарок'},
        ],
        price: 'от 19000 ₽',
        color: '#4834D4'
    },
    {
        icon: <Box pt={'1rem'} mx={'auto'} w={36}><IconDeviceMobileCode color="#FFF" size={36}/></Box>,
        title: 'Mobile',
        description: [
            {label: 'Создание макета приложения'},
            {label: 'Верстка приложения по макету'},
        ],
        price: 'от 25000 ₽',
        color: '#EB4D4B'
    },
    {
        icon: <Box pt={'1rem'} mx={'auto'} w={36}><IconLayoutGrid color="#FFF" size={36}/></Box>,
        title: 'Layout',
        description: [
            {label: 'Верстка сайта/приложения по макету'},
        ],
        price: 'от 5000 ₽ / стр.',
        color: '#30336B'
    },
    {
        icon: <Box pt={'1rem'} mx={'auto'} w={36}><IconBrandCodesandbox color="#FFF" size={36}/></Box>,
        title: 'Правки',
        description: [
            {label: 'Замена блоков в секции'},
            {label: 'Добавления контента в проект'},
        ],
        price: '1000 ₽ / 1 шт',
        color: '#535C68'
    },
]

interface ComponentProps {
    icon: any,
    title: string,
    description: object[],
    price: string,
    color: string,
}

export const SectionFive: React.FC = (props: Props) => {
  const [active, setActive] = useState(false)

  const Groups: React.FC<ComponentProps> = ({icon, title, description, price, color}) => {
    return (
      <Box className="sec-5-carousel-main" bg={color} mx={10}>
      {icon}
      <Text className="sec-5-carousel-title" mx={'auto'}>
          {title}
      </Text>
      {description.map((item: any, index: number) => (
          <Box key={index} mx={20} mb={24}>
              <Text className="sec-5-carousel-description">{item.label}</Text>
              <Divider size={1} color="#FFF"/>
          </Box>
      ))}
       <Box w={'100%'} mb={24} style={{position: 'absolute', bottom: 0}}>
        <Text className="sec-5-carousel-price">
          {price}
        </Text>
         <Button className="sec-5-carousel-button" mx={20} onClick={() => setActive(true)}>
            <Text className="sec-5-carousel-button-text">
              Заказать услугу
            </Text>
         </Button>
       </Box>
      </Box>
    )
  }

  return (
    <Box className="sec-5-main" id="price">
      {active === true
      ?
      <Box bg={'#0E0A0A'} w={'100%'} h={'100vh'} style={{top: 0, position: 'fixed', zIndex: 1000000}}>
        <IconX className="contact-icon" onClick={() => setActive(false)} size={32} color="#FFF"/>
        <Text className="contact-title">ЗАКАЗАТЬ УСЛУГУ</Text>
        <Container p={'6rem'} className="contact-container">
          <ContactPrice />
        </Container>
      </Box> : null}
      <Container maw={1180}>
          <Text className="sec-5-title">
          ПРАЙС УСЛУГ
          </Text>
          <Carousel
            slidesToScroll={1}
            initialSlide={1}
            loop
            previousControlIcon={<IconArrowBarToLeft color="#FFF" size={64} />}
            nextControlIcon={<IconArrowBarToRight color="#FFF" size={64} />}
            controlsOffset={'1rem'}
            >
            {data.map((item, index) => (
                <Groups key={index} icon={item.icon} title={item.title} description={item.description} price={item.price} color={item.color}/>
            ))}
          </Carousel>
          <Group position="left" className="sec-5-group">
            <Box className="sec-5-description-box">
              <Text className="sec-5-description-label">
                !
              </Text>
            </Box>
              <Text className="sec-5-description">
                Обязательна предоплата за проект (50% от стоимости услуги)
              </Text>
          </Group>
          <Group position="left" className="sec-5-group">
            <Box className="sec-5-description-box" id="sec-5-description-box-2">
              <Text className="sec-5-description-label">
                ?
              </Text>
            </Box>
              <Text className="sec-5-description">
              На этапе создания дизайн-макета правки бесплатно
              </Text>
          </Group>
      </Container>
    </Box>
  )
}