import { BackgroundImage, Box, Button, Container, Group, Flex, Text, TextInput, rem } from "@mantine/core"
import './SectionThree.css'
import { IconAddressBook, IconMail, IconCalendarEvent } from "@tabler/icons-react"
import { useRef, useState } from "react"

export const SectionThree = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [value, setValue] = useState('')
  const form = useRef()

  const mailOptions = {
    from: 'kirillgulyaev2002@gmail.com',
    to: 'kirillgulyaev2002@gmail.com',
    subject: 'Node.js почта',
    text: 'Это сообщение я отправил себе!!!'
  }

  const sendHandler = () => {
    
    alert('Ваш запрос отправлен!')
  };
  
  return (
    <Box className="sec-3-main" id="contact">
      <Container maw={1180}>
        <Flex direction={'column'} className="sec-3-flex">
          <Text className="sec-3-title">
            ОСТАВЬТЕ ЗАЯВКУ ЧТОБЫ ПОЛУЧИТЬ 100% КАЧЕСТВЕННУЮ <span>РАЗРАБОТКУ</span> ПРОЕКТА
          </Text>
          <form ref={form} onSubmit={sendHandler}>
          <TextInput 
              className="sec-3-input"
              rightSection={
                <Box w={'3rem'} h={'3rem'} mr={'2rem'} className="sec-3-icon">
                  <IconAddressBook size={'3rem'} color="#0E0A0A" className="sec-3-icon"/>
                </Box>
              }
              placeholder="Имя"
              id="name"
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
          />
          <TextInput
              className="sec-3-input"
              rightSection={
                <Box w={'3rem'} h={'3rem'} mr={'2rem'} className="sec-3-icon">
                  <IconMail size={'3rem'} color="#0E0A0A" className="sec-3-icon"/>
                </Box>
              }
              placeholder="Почта"
              id="email"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
          />
          <TextInput
              className="sec-3-input"
              rightSection={
                <Box w={'3rem'} h={'3rem'} mr={'2rem'} className="sec-3-icon">
                  <IconCalendarEvent size={'3rem'} color="#0E0A0A" className="sec-3-icon"/>
                </Box>
              }
              placeholder="Когда с вами связаться?"
              name="message"
              value={value}
              onChange={e => setValue(e.target.value)}
          />
          <Button className="sec-3-button" type="submit">
            <Text className="sec-3-button-text">
              Отправить
            </Text>
          </Button>
          </form>
          <Text className="sec-3-description">
            Нажимая на кнопку <span>Отправить</span>, вы даете согласие на обработку персональных данных и соглашаетесь c политикой конфиденциальности
          </Text>
        </Flex>
      </Container>
    </Box>
  )
}
