import { Routes, Route } from "react-router-dom"

import { HomePage } from "../pages/HomePage"

type Props = {}

export const RoutesComponent: React.FC = (props: Props) => {
  return (
    <Routes>
        <Route path="/" element={<HomePage />}/>
    </Routes>
  )
}