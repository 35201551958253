import { BackgroundImage, Box, Button, Container, Center, Text, Group, } from "@mantine/core"
import './SectionSeven.css'
import { useState } from "react"
import { IconExternalLink } from "@tabler/icons-react"

type Props = {}

const portfolioImage = [...Array(2)].map((_, i) => require(`../images/portfolio/${i}.png`))

const portfolioData = [
    {
        title: 'Сайт для студии мебели “Амалия-home”',
        href: 'https://amalyahome.ru',
        image: portfolioImage[0]
    },
    {
        title: 'Настольная игра “Бункер”',
        href: 'https://kirillgulyaev.store',
        image: portfolioImage[1]
    }
]

interface ComponentProps {
    title: string,
    href: string,
    image: any,
}

const PortfolioContainer: React.FC<ComponentProps> = ({title, href, image}) => {
    const [active, setActive] = useState(false)
    return (
      <>
        <Box w={'max-content'} className="portfolio-main">
          <BackgroundImage component="a" href={href} src={image} className="portfolio-image">
            <Box bg={'#EB4D4B'}>
              <IconExternalLink className="portfolio-icon" size={48} color="#FFF" style={{padding: '1rem'}}/>  
            </Box>  
          </BackgroundImage>
          <Text className="portfolio-title">
          {title}  
          </Text>   
        </Box>  
      </>
    )
}

export const SectionSeven: React.FC = (props: Props) => {
  return (
    <Box className="sec-7-main" id="portfolio">
      <Container maw={1180} pt={'6rem'} pb={'16rem'} className="sec-7-container">
        <Text className="sec-7-title">
            ПОРТФОЛИО МОИХ РАБОТ
        </Text>
        <Group position="center" mt={'4rem'} className="sec-7-group">
        {portfolioData.map((item, index) => (
            <PortfolioContainer key={index} title={item.title} href={item.href} image={item.image} />
        ))}
        </Group>
      </Container>    
    </Box>
  )
}