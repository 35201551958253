import React from 'react';
import './App.css';

import { RoutesComponent } from './routes/RoutesComponent';

function App() {
  return (
    <RoutesComponent />
  );
}

export default App;
