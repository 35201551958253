import { Box, Button, Collapse, Container, Group, Flex, Text,} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import './SectionSix.css'
import { IconArrowDown } from "@tabler/icons-react"


type Props = {}

const data = [
    {
        title: '1. Создание дизайн макета проекта',
        content: 'Макет создается в программе для Web-дизайна - Figma. Подбор цветов, рисование блоков проекта происходит на данном этапе. Любые правки на данном этапе бесплатно'},
    {
        title: '2. Верстка проекта по дизайн макету',
        content: 'Написание кода по ранее нарисованному макету. Создание компонентов на базе языка программирования JavaScript (TypeScript) с применением фреймворка React'
    },
    {
        title: '3. Настройка формы обратной связи',
        content: 'Подключение сервиса для работы формы обратной связи на сайте'
    },
    {
      title: '4. Правки проекта',
      content: 'При необходимости делаются правки проекта за определенную плату'
    },
]

export const SectionSix: React.FC = (props: Props) => {
    const [opened, { toggle }] = useDisclosure(false);
    const [active, setActive] = useDisclosure(false)
    const [collapsed, setCollapsed] = useDisclosure(false)
    const [collapsed2, setCollapsed2] = useDisclosure(false)

    const clickHandler = () => {
        setActive.toggle()
    }
    const collapseHandler = () => {
        setCollapsed.toggle()
    }
    const collapseHandler2 = () => {
      setCollapsed2.toggle()
  }

  return (
    <Box className="sec-6-main" h={'max-content'} pb={'4rem'}>
      <Container maw={1180}>
        <Text className="sec-6-title">
        ЭТАПЫ РАЗРАБОТКИ ПРОЕКТА
        </Text>
        <Box className="sec-6-title-block"></Box>
             <Box mx={'auto'} className="sec-6-box">
              <Button className="sec-6-collapse-main" onClick={toggle}>
                <Group position='apart' className="sec-6-collapse-group">
                <Text className="sec-6-collapse-title">
                  {data[0].title}
                </Text>
                <IconArrowDown className="sec-6-collapse-icon" color="#FFF" size={32} style={{rotate: opened === true ? `180deg` : `360deg`}}/>
                </Group>
               </Button>
                <Collapse className="sec-6-collapse-content" in={opened}>
                 <Text className="sec-6-collapse-content-text">{data[0].content}</Text>
                </Collapse>
              </Box>
              <Box mx={'auto'} className="sec-6-box">
              <Button className="sec-6-collapse-main" onClick={() => clickHandler()}>
                <Group position='apart' className="sec-6-collapse-group">
                <Text className="sec-6-collapse-title">
                  {data[1].title}
                </Text>
                <IconArrowDown className="sec-6-collapse-icon" color="#FFF" size={32} style={{rotate: active === true ? `180deg` : `360deg`}}/>
                </Group>
               </Button>
                <Collapse className="sec-6-collapse-content" in={active}>
                  <Text className="sec-6-collapse-content-text">{data[1].content}</Text>
                </Collapse>
              </Box>
              <Box mx={'auto'} className="sec-6-box">
              <Button className="sec-6-collapse-main" onClick={() => collapseHandler()}>
                <Group position='apart' className="sec-6-collapse-group">
                <Text className="sec-6-collapse-title">
                  {data[2].title}
                </Text>
                <IconArrowDown className="sec-6-collapse-icon" color="#FFF" size={32} style={{rotate: collapsed === true ? `180deg` : `360deg`}}/>
                </Group>
               </Button>
                <Collapse className="sec-6-collapse-content" in={collapsed}>
                 <Text className="sec-6-collapse-content-text">{data[2].content}</Text>
                </Collapse>
              </Box>
              <Box mx={'auto'} className="sec-6-box">
              <Button className="sec-6-collapse-main" onClick={() => collapseHandler2()}>
                <Group position='apart' className="sec-6-collapse-group">
                <Text className="sec-6-collapse-title">
                  {data[3].title}
                </Text>
                <IconArrowDown className="sec-6-collapse-icon" color="#FFF" size={32} style={{rotate: collapsed2 === true ? `180deg` : `360deg`}}/>
                </Group>
               </Button>
                <Collapse className="sec-6-collapse-content" in={collapsed2}>
                 <Text className="sec-6-collapse-content-text">{data[3].content}</Text>
                </Collapse>
              </Box>
      </Container>
    </Box>
  )
}