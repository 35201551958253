import { BackgroundImage, Box, Button, Container, Text, Group, Flex, } from "@mantine/core"
import './SectionEight.css'

const avatar = require(`../images/avatar2.png`)

type Props = {}

const data = [
   {
    title: 'Напишите мне в Telegram',
    href: 'https://t.me/toxic_arrr',
    color: '#2AABEE'
   },
   {
    title: 'Напишите мне в Whatsapp',
    href: 'https://wa.me/+79383456532',
    color: '#25D366'
   },
   {
    title: 'ВКонтакте',
    href: 'https://vk.com/toxic_arrr',
    color: '#4C75A3'
   },
   {
    title: 'Электронная почта',
    href: 'mailto:kirillgulyaev2002@gmail.com?body=Привет',
    color: '#C71610'
   }
]

export const SectionEight: React.FC = (props: Props) => {
  return (
    <Box className="sec-8-main" id="contact">
      <Container maw={1180} pt={'6rem'} pb={'4rem'} className="sec-8-container">
        <Text className="sec-8-title">
            СВЯЗЬ СО МНОЙ
        </Text>
        <Box className="sec-6-title-block"></Box>
        <Group mt={'6rem'} className="sec-8-group">
            <img src={avatar} className="sec-8-image" loading="lazy"/>
            <Flex direction={'column'} className="sec-8-flex">
                <Text className="sec-8-flex-title">
                Возможные способы связаться со мной напрямую
                </Text>
                {data.map((item, index) => (
                    <Button key={index} bg={item.color} component="a" href={item.href}
                        className="sec-8-button"
                        sx={{
                            '&:hover': {background: item.color}
                        }}
                    >
                        <Text key={index} className="sec-8-button-text">{item.title}</Text>
                    </Button>
                ))}
            </Flex>
        </Group>
      </Container>    
    </Box>
  )
}