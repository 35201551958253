import { BackgroundImage, Box } from "@mantine/core";
import logo from '../../images/logo.png'

export const LogoDivider: React.FC = (type: any) => {
    
    return (
        <Box h={'4rem'} bg={'#0E0A0A'}>
            <BackgroundImage 
                src={logo}
                w={'4rem'}
                h={'100%'}
                mx={'auto'}
            />
        </Box>
    )
}