import { useState } from "react"
import { BackgroundImage, Box, Button, Container, Center, Text, } from "@mantine/core"
import './SectionOne.css'
import './Contact.css'
import background from '../images/background.png'
import { ContactForm } from "./ContactForm"
import { IconX } from "@tabler/icons-react"

type Props = {}

export const SectionOne: React.FC = (props: Props) => {
  const [active, setActive] = useState(false)
  
  return (
    <Box className="sec-1-main">
      {active === true
      ?
      <Box bg={'#0E0A0A'} w={'100%'} h={'100vh'} style={{position: 'fixed', zIndex: 1000000}}>
        <IconX className="contact-icon" onClick={() => setActive(false)} size={32} color="#FFF"/>
        <Text className="contact-title">ОБРАТНАЯ СВЯЗЬ</Text>
        <Container p={'6rem'} className="contact-container">
          <ContactForm />
        </Container>
      </Box> : null}
      <Box className="sec-1-background">
          <Container maw={1180}>
            <Text className="sec-1-title">
              Разработка клиентской части <span>Web-сайтов</span> и мобильных приложений
            </Text>
            <Text className="sec-1-subtitle">
              Собственный IT-продукт - это поддержание  бренда вашей компании!
            </Text>
            <Button className="sec-1-button" onClick={() => setActive(true)}>
              <Text className="sec-1-button-text">
                Обратная связь
              </Text>
            </Button>
          </Container>    
      </Box>
    </Box>
  )
}
