import { SectionOne } from "../components/SectionOne"
import { SectionTwo } from "../components/SectionTwo"
import { SectionThree } from "../components/SectionThree"
import { LogoDivider } from "../components/dividers/LogoDivider"
import { SectionFour } from "../components/SectionFour"
import { SectionFive } from "../components/SectionFive"
import { RedDivider } from "../components/dividers/RedDivider"
import { SectionSix } from "../components/SectionSix"
import { RedDividerText } from "../components/dividers/RedDividerText"
import { ContinuousTextAnimation } from "../animation/ContinuousTextAnimation"
import { SectionSeven } from "../components/SectionSeven"
import { SectionEight } from "../components/SectionEight"
import { FooterLinks } from "../components/FooterLinks"
import { HeaderMenu } from "../components/Header"

const data = [
    {component: <HeaderMenu />},
    {component: <SectionOne />},
    {component: <SectionTwo />},
    {component: <LogoDivider />},
    {component: <SectionThree />},
    {component: <LogoDivider />},
    {component: <ContinuousTextAnimation />},
    {component: <SectionFour />},
    {component: <RedDivider />},
    {component: <SectionFive />},
    {component: <RedDivider />},
    {component: <SectionSix />},
    {component: <RedDividerText />},
    {component: <SectionSeven />},
    {component: <SectionEight />},
    {component: <FooterLinks />}
]

interface ComponentProps {
    component: JSX.Element
}

const SectionsContainer: React.FC<ComponentProps> = ({ component }) => { return component }

type Props = {}

export const HomePage: React.FC = (props: Props) => {
  return (
    <>
    {data.map((item, index) => (
        <SectionsContainer key={index} component={item.component} />
    ))}
    </>
  )
}
