import { Text, Container, ActionIcon, Group, rem, BackgroundImage, Flex, SimpleGrid, Divider } from '@mantine/core';
import { IconBrandTelegram, IconBrandYoutube, IconBrandVk } from '@tabler/icons-react';

import './FooterLinks.css';

const logo = require('../images/logo.png')

const data = [
  {
    title: 'Навигация',
    links: [
      { label: 'Обо мне', link: '#about' },
      { label: 'Прайс', link: '#price' },
      { label: 'Портфолио', link: '#portfolio' },
      { label: 'Обратная связь', link: '#contact' },
    ],
  },
  {
    title: 'Интересное',
    links: [
      { label: 'Я на YouTube', link: 'https://www.youtube.com/@toxic_arrr' },
      { label: 'Мой Tiktok', link: '#' },
      { label: 'Паблик ВКонтакте', link: '#' },
      { label: 'Telegram канал', link: '#' },
    ],
  },
  {
    title: 'Коннект',
    links: [
      { label: 'ВКонтакте', link: 'https://vk.com/toxic_arrr'},
      { label: 'WhatsApp', link: 'https://wa.me/+79383456532' },
      { label: 'Telegram', link: 'https://t.me/toxic_arrr' },
    ],
  },
];

export function FooterLinks() {
  const groups = data.map((group) => {
    const links = group.links.map((link, index) => (
      <Text
        key={index}
        className='footer-links'
        component="a"
        href={link.link}
        
      >
        {link.label}
      </Text>
    ));

    return (
      <Flex direction={'column'} key={group.title}>
        <Text className='footer-links-title'>{group.title}</Text>
        {links}
      </Flex>
    );
  });

  return (
    <footer className='footer-main'>
      <Container maw={1180}>
        <Group position='apart' pt={200} mb={'4rem'} className='footer-group'>
         <Flex direction={'column'} className='footer-flex-logo'>
          <BackgroundImage src={logo} className='footer-logo'/>
          <Text size="xs" className='footer-underlogo'>
          Разработка Web-сайтов и мобильных приложений
          </Text>
         </Flex>
        <SimpleGrid w={'50%'} cols={3} className='footer-grid'>{groups}</SimpleGrid>
        </Group>
      
        <Divider size={1} color='#FFF'/>
        <Group position='apart' mx={'2rem'} mt={'4rem'} pb={135} className='footer-group-2'>
        <Text c={'gray'} className='footer-rights' size="sm">
          © 2024 Кирилл Гуляев. Все права защищены. 
        </Text>

         <Group className='footer-links-group'>
          <ActionIcon size="lg" color="gray" variant="subtle" component='a' href='https://t.me/toxic_arrr'>
            <IconBrandTelegram style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg" color="gray" variant="subtle" component='a' href='https://www.youtube.com/@toxic_arrr'>
            <IconBrandYoutube style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg" color="gray" variant="subtle" component='a' href='https://vk.com/toxic_arrr'>
            <IconBrandVk style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
          </ActionIcon>
         </Group>
        </Group>
      </Container>
    </footer>
  );
}