import { useRef } from "react";
import { Box, Button, Container, Group, Text, TextInput, Textarea } from '@mantine/core';
import { IconAddressBook, IconMail } from "@tabler/icons-react";
import './Contact.css'

export const ContactPrice = () => {
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();
    
        alert('Ваш запрос отправлен!')
    };

    return (
        <form ref={form} onSubmit={sendEmail}>
            <TextInput
              rightSection={
                <Box w={'3rem'} h={'3rem'} mr={'2rem'} className="sec-3-icon">
                  <IconAddressBook size={'3rem'} color="#0E0A0A" className="sec-3-icon"/>
                </Box>
              }
              placeholder="Имя"
              id="name"
              name="user_name"
              type="text"
          />
          <TextInput
              rightSection={
                <Box w={'3rem'} h={'3rem'} mr={'2rem'} className="sec-3-icon">
                  <IconMail size={'3rem'} color="#0E0A0A" className="sec-3-icon"/>
                </Box>
              }
              placeholder="Почта"
              id="email"
              name="user_email"
              type="email"
          />
          <Textarea placeholder='Укажите наименование услуги' name='message' className="contact-area"/>
          <Button type="submit" className="contact-button">
            <Text className="contact-button-text">Отправить</Text>
          </Button>
        </form>
    )
}