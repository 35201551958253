import { Box, Button, Container, Group, Flex, Text, } from "@mantine/core"
import './SectionTwo.css'
import avatar from '../images/avatar.png'

type Props = {}

export const SectionTwo: React.FC = (props: Props) => {
  return (
    <Box className="sec-2-main" id="about">
      <Container maw={1180}>
          <Group position="center" className="sec-2-group">
            <img alt="Аватар" src={avatar} className="sec-2-logo" loading="lazy"/>
             <Flex direction={'column'} className="sec-2-flex">
                <Text className="sec-2-title">
                Привет! <br/>
                Меня зовут Кирилл Гуляев и я Frontend-разработчик
                </Text>
                <Text className="sec-2-subtitle" my={'2rem'}>
                Я занимаюсь разработкой клиентской части Web-сайтов и мобильных приложений на базе Android более 3-х лет
                </Text>
                <Button component="a" href="https://t.me/toxic_arrr" className="sec-2-button">
                    <Text className="sec-2-button-text">
                    Напишите мне в Telegram
                    </Text>
                </Button>
             </Flex>
          </Group>
      </Container>
    </Box>
  )
}
