import { useRef, useEffect } from "react";
import { Box, Container, Text } from "@mantine/core";
import Typed from "typed.js";

export const RedDividerText: React.FC = (type: any) => {
  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ['СОЗДАНИЕ WEB-САЙТОВ И МОБИЛЬНЫХ ПРИЛОЖЕНИЙ'],
      typeSpeed: 75, // Скорость набора
      backSpeed: 50, // Скорость удаления текста
      startDelay: 500, // Задержка перед началом анимации
      backDelay: 1500, // Задержка перед удалением текста
      loop: true,
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.reset()
    };
    }, []);

    return (
        <Box className="red-divider-box">
          <Container maw={1180}>
           <Text className="red-divider-text" ref={el}></Text>
          </Container>
        </Box>
    )
}